/* eslint-disable prettier/prettier */

'use strict';

var debounce = require('lodash/debounce');
var { lock, clearBodyLocks } = require('tua-body-scroll-lock');
var { isIOSUserAgent } = require('../helpers/userAgent');

/**
 * Generate css variables with the header height current values
 */
function calculateCustomProperties() {
    var headerHeight = 0;
    $('.unified-header').each(function () {
        headerHeight += $(this).outerHeight(true);
    });
    var menuOnlyHeight = $('header').outerHeight(true) || 0;
    var searchHeight = $('.search-container').outerHeight(true) || 0;

    if (headerHeight) {
        $(':root').css('--header-height', `${headerHeight}px`);
        $(':root').css('--header-height-with-search', `${headerHeight + searchHeight}px`);
    }
    if (menuOnlyHeight) {
        $(':root').css('--header-height-menu-only', `${menuOnlyHeight}px`);
        $(':root').css('--header-height-menu-only-with-search', `${menuOnlyHeight + searchHeight}px`);
    }
}

/**
 * Load custom css properties on load and when the header size changes
 */
function loadCustomProperties() {
    $(window).on('resize', debounce(function () {
        calculateCustomProperties();
    }, 500));
    var searchBar = document.querySelector('.search-container');
    if (searchBar) {
        new ResizeObserver(calculateCustomProperties).observe(document.querySelector('.search-container'));
    }

    calculateCustomProperties();
}

/**
 * Reinitializes desktop menu's status by hidding any subcategory modal and deselecting every subcategory
 */
function reinitializeDesktopMenuStatus() {
    $('.menu-modal.show').removeClass('show');
    $('.menu-modal .menu-item--active').removeClass('menu-item--active');
}

/**
 * Reinitializes mobile menu's status by closing any expanded category and closing the menu itself
 */
function reinitializeMobileMenuStatus(menu) {
    $('.hamburger-navigation .m-menu__checkbox').prop('checked', false);
    $('body').removeClass('overflow-hidden');
    if (isIOSUserAgent) {
        clearBodyLocks();
    }
}

module.exports = function () {
    // Open desktop subcategory menu modal
    $('.dropdown:not(.disabled) [data-toggle="dropdown"]').on('click', function (e) {
        e.preventDefault();
        var activeMenu = $('.menu-modal.show');
        var categoryId = $(this)[0].id;
        // To avoid problems with the native bootstrap behaviour, just add the show class to the one that
        // we have to show and remove it from the other ones instead of using the modal function
        reinitializeDesktopMenuStatus();
        if (!activeMenu.length || !activeMenu.is($(`#menu-modal-${categoryId}`))) {
            $(`#menu-modal-${categoryId}`).addClass('show');
        }
        $('body').removeClass('modal-open');
    });

    // Handle desktop menu subcategories navigation
    $('.menu-modal .menu-submenu-list__item.submenu-link .tbm-link').on('click', function (e) {
        e.preventDefault();

        if (!$(this).hasClass('menu-item--active')) {
            // Set other elements from the same menu as not active
            $(this).parent().siblings().find('.menu-item--active')
                .removeClass('menu-item--active');
            // Set the clicked element as active
            $(this).addClass('menu-item--active');
            var menuToShow = $(`.menu-submenu-list[data-parent='${$(this)[0].id}']`);
            var levelToShow = menuToShow.data('level');
            // Get submenus from the same level and above
            var submenusToHide = $(`.menu-submenu-list[data-parent!='${$(this)[0].id}']`).filter(function () {
                return $(this).data('level') >= levelToShow;
            });
            // Hide the other submenus with level >= selected level and set all their elements as not active
            submenusToHide.removeClass('menu-item--active').find('.menu-item--active').removeClass('menu-item--active');
            menuToShow.addClass('menu-item--active');
        }
    });

    // When closing the desktop menu, reset it's status
    $(document).on('click', function (event) {
        if ($('.menu-modal.show').length
            && (!$(event.target).closest('#block-mainnavigation').length
                || $(event.target).is($('.menu-modal .menu-close')))) {
            reinitializeDesktopMenuStatus();
        }
    });

    // When closing the mobile menu, reset it's status
    $('.hamburger-navigation #menu').on('change', function () {
        if (!$(this).is(':checked')) {
            reinitializeMobileMenuStatus($(this));
        } else {
            $('body').addClass('overflow-hidden');
            if (isIOSUserAgent) {
                lock($('.unified-header ul[data-region=navigation_hamburger]')[0]);
            }
        }
    });

    // Close and reset the mobile/desktop menu's status when they are not used
    $(window).on('resize', debounce(function () {
        if ($(window).innerWidth() >= 992 && $('.hamburger-navigation #menu').is(':checked')) {
            reinitializeMobileMenuStatus($('.hamburger-navigation #menu'));
            $('.hamburger-navigation #menu').prop('checked', false);
        } else if ($(window).innerWidth() < 992 && $('.menu-modal.show').length) {
            reinitializeDesktopMenuStatus();
        }
    }, 500));

    loadCustomProperties();
};
