'use strict';

module.exports = function (element) {
    var headerHeight = parseInt($(':root').css('--header-height-menu-only-with-search'), 10) || 0;
    var position = element && element.length ? element.offset().top : 0;
    $('html, body').animate(
        {
            scrollTop: position - headerHeight
        },
        500
    );
    if (!element) {
        $('.logo-home').focus();
    }
};
