'use strict';

module.exports = function () {
    $('body').on('click', '.external-redirect-link', function (e) {
        e.preventDefault();

        $('.modal').modal('hide');
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
        $('#externalUrlModal .externalUrlModal-modal__confirmation-btn').data('href', $(this).attr('href'));
        $('#externalUrlModal').modal('show');
    });

    $('body').on('click', '#externalUrlModal .externalUrlModal-modal__confirmation-btn', function (e) {
        window.open($(this).data('href'), '_blank', 'noopener,noreferrer');
    });
};
