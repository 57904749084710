'use strict';

/**
 * True if an IOS device is being used
 */
var isIOSUserAgent =
    typeof window !== 'undefined' &&
    typeof window.navigator !== 'undefined' &&
    typeof window.navigator.userAgent === 'string' &&
    /ipad|iphone|ipod/.test(window.navigator.userAgent.toLowerCase());

module.exports = { isIOSUserAgent };
